@import "../../layout/theme";

.searchFieldContainer {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  margin: 20px 0;
  flex-direction: row;
  @media screen and (max-width: $screen-break) {
    flex-direction: column;
  }
}

.searchFieldBox {
  width: 25%;
  margin: 10px;
  @media screen and (max-width: $screen-break) {
    width: 90%;
  }
}

.searchFieldLabel {
  width: 100%;
  font-weight: 500;
  letter-spacing: 2px;
  font-size: large;
}

.searchField {
  width: 100%;
}

.searchButtonContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 10px 0 30px 0;
}

.calendarRoot {
  overflow: hidden;
}

.iframeContainer {
  width: 100%;
  height: 100%;
  overflow: hidden;
  padding-left: 20px;
  @media screen and (max-width: $screen-break) {
    padding-left: 10px;
  }
}

// https://stackoverflow.com/questions/16670931/hide-scroll-bar-but-while-still-being-able-to-scroll
// This is done to hide overflow scrollbar
.calendarContainerChild {
  width: 100%;
  height: 100%;
  overflow-y: scroll;
  padding-right: 17px; /* Increase/decrease this value for cross-browser compatibility */
  box-sizing: content-box; /* So the width will be 100% + 17px */
}


.monthContainer {
  padding: 3vw 2vw 3vw 0;
  @media screen and (max-width: $screen-break) {
    padding: 3vw 3vw 3vw 0;
  }
}

.monthTable {
  width: 100%;
  margin: 0 auto;
  border-collapse: collapse;
}

.monthTable thead tr th, .monthTable thead tr td {
  padding: 0.5vw;
  font-size: 1vw;
  @media screen and (max-width: $screen-break) {
    font-size: 2vw;
    padding: 2vw;
  }
}

.monthTable thead tr:nth-child(1) {
  background-color: $color1BackgroundDark;
  color: $color1Light;
}

.monthTable thead tr:nth-child(1) th {
  font-size: 3vw;
  padding: 1vw;
  @media screen and (max-width: $screen-break) {
    font-size: 5vw;
    padding: 3vw;
  }
}



.monthTable thead tr:nth-child(2) {
  background-color: $color3BackgroundLight;
  color: $colorTextDark;
}

.monthTable thead tr:nth-child(2) th {
  text-align: left;
}

.monthTable thead tr:nth-child(2) th:nth-child(1) {
  width: 6vw;
}

.monthTable thead tr:nth-child(2) th:nth-child(2) {
  width: 6vw;
}

.monthTable thead tr:nth-child(3) {
  background-color: $color4BackgroundLight;
  color: $colorTextDark;
}

.monthTable tbody tr:nth-child(odd) {
  background-color: #eaeaea;
  color: $colorTextDark;
}


.monthTable tbody tr:nth-child(even) {
  background-color: #fafafa;
  color: $colorTextDark;
}

.monthTable tbody tr td {
  padding: .5vw;
  font-size: 1vw;
  white-space: nowrap;
  @media screen and (max-width: $screen-break) {
    font-size: 2vw;
    padding: 1vw;
  }
}

.monthTable tbody tr:hover {
  background-color: $color4BackgroundLight;
}

.headingContainer {
  text-align: center;
}

.headingText {
  font-size: 3vw;
  padding: 2vw;
  @media screen and (max-width: $screen-break) {
    font-size: 5vw;
  }
}

.headingCompanyName {
  font-weight: lighter;
  letter-spacing: 3px;
  font-size: 1.5vw;
  @media screen and (max-width: $screen-break) {
    font-size: 3vw;
  }
}

.headingCompanyAddress {
  color: gray;
  padding-bottom: 2vw;
}

.printLink {
  text-align: right;
  padding-right: 2vw;
  font-size: 1.5vw;
  @media screen and (max-width: $screen-break) {
    font-size: 4vw;
    padding-right: 8vw;
  }
}
