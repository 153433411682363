@import "../theme";

.container {
  @extend .card-2;
  background-color: $color1BackgroundDark;
  color: $colorTextLight;
  padding: 3vw 0;
  display: flex;
  align-content: center;
  justify-content: center;
}

.content {
  // background-color: red;
  width: $screen-width;
  padding: 0 $content-padding;
  @media screen and (max-width: $screen-break) {
    width: $screen-width-mobile;
    padding: 0 $content-padding-mobile;
  }
}

.logoContainer {
  font-size: 2vw;
  @media screen and (max-width: $screen-break) {
    font-size: 5vw;
  }
}

.titleContainer {
  font-size: 1.5vw;
  padding-top: 1vw;
  @media screen and (max-width: $screen-break) {
    padding-top: 2vw;
    font-size: 3vw;
  }
}
