@import "../CompanyDashboardTheme";

.salahTimeTable {
    margin: 0 auto;
    font-size: 1.2vw;
    border-collapse: collapse;
    max-width: 88%;
    @media screen and (max-width: $screen-break) {
        font-size: 3.5vw;
    }
}

table.salahTimeTable th, table.salahTimeTable td {
    border: 1px solid $color1;
    padding: 5px;
    white-space: nowrap;
}

table.salahTimeTable th:nth-child(4), table.salahTimeTable td:nth-child(4) {
    font-size: 1vw;
    @media screen and (max-width: $screen-break) {
        font-size: 3vw;
    }
}

.salahTimeDateHeading {
    padding: 6vw 0 2vw 0;
    font-size: 1.5vw;
    white-space: nowrap;
    @media screen and (max-width: $screen-break) {
        font-size: 4vw;
        padding: 18vw 0 6vw 0;
    }
}

.red_blink_text {
    animation: blinker 2s linear infinite;
    color: tomato;
    font-weight: bold;
}

@keyframes blinker {
    0% { opacity: 0.1; }
}
