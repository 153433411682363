@import "./Calendar.module";

.searchFieldContainer, .searchButtonContainer, .printLink {
  display: none;
}

.monthTable thead tr th, .monthTable thead tr td, .monthTable tbody tr td {
  border: 1px solid #ccc;
}

.monthTable thead tr:nth-child(1), .monthTable thead tr:nth-child(3) {
  background-color: white;
  color: black;
}

.monthTable thead tr:nth-child(2) {
  background-color: lightgray;
  color: black;
}