@import "../adminTheme";

.mainDescription {
  margin-top: 20px;
}

.container {
  display: flex;
  margin: 20px 0 40px 0;
  flex-direction: row;
  @media screen and (max-width: $screen-break) {
    flex-direction: column;
  }
}

.left {
  width: 50%;
  background-color: #f9f7ee;
  padding: 20px;
  border-radius: 5px 0 0 5px;
  color: rgba(0, 0, 0, 0.60);
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  @media screen and (max-width: $screen-break) {
    width: 100%;
  }
}

.name {
  font-size: 30px;
  letter-spacing: 3px;
  text-align: center;
  margin-bottom: 20px;
}

.description {
  font-size: large;
}

.script {
  border: 1px solid #c3c3c3;
  padding: 10px;
  margin-top: 20px;
  background: #e3e3e3;
  border-radius: 3px;
  font-family: "Lucida Console", Monaco, monospace;
  height: 15em;
  width: 100%;
  resize: none;
}

.right {
  width: 50%;
  background-color: #f9f5dd;
  border-radius: 0 5px 5px 0;
  padding: 20px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  border-right: 1px solid rgba(0, 0, 0, 0.1);
  @media screen and (max-width: $screen-break) {
    width: 100%;
  }
}

.image {
  display: flex;
  justify-content: center;
  align-content: center;
  flex-direction: row;
}

.image img {
  max-width: 300px;
}

.imageDescription {
  color: rgba(0, 0, 0, 0.60);
  text-align: center;
  font-style: italic;
  font-size: smaller;
  margin-top: 10px;
}

.imageDescription a {
  color: rgba(70, 0, 0, 0.6);
}
