@import "CompanyDashboardTheme";

.gridContainer {
    height: 100%;
    background-repeat: no-repeat;
    background-size: 100% 100%;
    background-position: center;
    @media screen and (max-width: $screen-break) {
        background-size: initial;
        // background-image: "url()";
    }
}

.gridColor {
    background-color: #2b2e3dc9;
    @media screen and (max-width: $screen-break) {
        background-color: #2b2e3d;
    }
}

.gridSide {
    @extend .gridColor;
}

.gridCenter {
    @extend .gridColor;
}

.box {
    text-align: center;
    color: black;
    margin: 0 auto;
    background-repeat: no-repeat;
    background-size: 100% 100%;
    overflow: hidden;
    position: relative;
};

.sideBox {
    @extend .box;
    margin-top: 8.5vw;
    height: 41.5vw;
    width: 32vw;
    @media screen and (max-width: $screen-break) {
        margin-top: 6vw;
        height: 120vw;
        width: 92vw;
    }
}

.sideBoxLeft {
    @extend .sideBox;
    margin-top: 1vw;
    @media screen and (max-width: $screen-break) {
        margin-top: 6vw;
    }
}

.centerBox {
    @extend .box;
    margin-top: 1vw;
    height: 49vw;
    width: 32vw;
    @media screen and (max-width: $screen-break) {
        margin-top: 6vw;
        height: 142vw;
        width: 100vw;
    }
}

.companyName {
    color: $colorTextLight;
    font-size: 2vw;
    text-align: center;
    text-shadow: 2px 2px 2px rgba(0, 0, 0, 0.45);
    margin-top: 3vw;

    @media screen and (max-width: $screen-break) {
        font-size: 5vw;
        margin-top: 5vw;
    }
}

.companyAddress {
    color: $colorTextLight;
    text-align: center;
    font-size: 1.2vw;
    @media screen and (max-width: $screen-break) {
        font-size: 2vw;
    }
}

.companyName a {
    color: $colorTextLight;
    text-decoration: none;
}

.settings {
    position: absolute;
}

.settings a {
    color: rgba(255, 255, 255, 0.40);
    @media screen and (max-width: $screen-break) {
        color: rgba(255, 255, 255, 0.20);
    }
}

.settings a span {
    font-size: 2vw;
    @media screen and (max-width: $screen-break) {
        font-size: 5vw;
    }
}
