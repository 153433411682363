@import "../theme";

.mainFooter {
  @extend .centerFlex;
  border-top: 1px solid #05364f;
  background-color: $color1BackgroundDark;
  color: $colorTextLight;
  width: 100%;
  z-index: 100;
  position: relative;
  bottom: 0;
  left: 0;
  height: 60px;
  flex-direction: column;
  // margin-top: 3vw;
}

.mainFooterContent {
  @extend .centerFlex;
}

.mainFooterBreak {
  margin: 0 10px;
}
