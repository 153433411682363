@import "../layout/theme";

$borderRadius: 5px;

.container {
  @extend .centerFlex;
  // height: 100%;
  padding: 0vw;
  flex-direction: column;
  background-color: #eee;
  @media screen and (max-width: $screen-break) {
    padding: 1vw;
  }
}

.mainBox {
  @extend .card-2;
  width: 80%;
  // height: 100%;
  border-radius: $borderRadius;
  //box-shadow: 2px 2px 5px 0px rgba(0, 0, 0, 0.40);
  display: flex;
  flex-direction: row;
  margin: 3vw auto 6vw auto;
  @media screen and (max-width: $screen-break) {
    flex-direction: column;
    width: 100%;
    height: 100%;
    margin-bottom: 100px;
  }
}

.welcome {
  @extend .centerFlex;
  background-color: $color1BackgroundDark;
  flex-grow: 1;
  color: white;
  border-radius: $borderRadius 0 0 $borderRadius;
  flex-direction: column;
  @media screen and (max-width: $screen-break) {
    border-radius: $borderRadius $borderRadius 0 0;
  }

}

.login {
  @extend .centerFlex;
  background-color: $color2BackgroundWhite;
  flex-grow: 1;
  border-radius: 0 $borderRadius $borderRadius 0;
  flex-direction: column;
  @media screen and (max-width: $screen-break) {
    border-radius: 0 0 $borderRadius $borderRadius;
  }
}

.content {
  @extend .centerFlex;
  flex-direction: row;
  padding: 0;
  @media screen and (max-width: $screen-break) {
    padding: 8vw 0;
  }
}
