// TODO Set body font in here instead of index.css
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;700&display=swap');

$h1-color: #696969;
// Dashboard colors
$color1: #b8b72fff;
$color2: #ecedd1ff;
$colorTextLight: #f2f2f2;
$colorTextDark: rgba(0,0,0, 0.90);;

// Admin Colors
$color1BackgroundDark: #07537c;
$color1Light: rgba(255, 255, 255, 0.90);

$color2BackgroundWhite: #f8f8f8;
$color3BackgroundLight: #bcdae9;
$color4BackgroundLight: #d0e5ef;


$screen-break: 959px;
$screen-width: 85%;
$screen-width-mobile: 95%;

$content-padding: 3%;
$content-padding-mobile: 5%;

* {
  box-sizing: border-box;
}

body {
  font-family: 'Roboto', sans-serif;
  // background-color: red;
}

// ########## Text

.heading1 {
  font-size: 2.2vw;
  @media screen and (max-width: $screen-break) {
    font-size: 6vw;
  }
}

.heading2 {
  font-size: 2vw;
  @media screen and (max-width: $screen-break) {
    font-size: 5vw;
  }
}

.heading3 {
  font-size: 1.8vw;
  @media screen and (max-width: $screen-break) {
    font-size: 4vw;
  }
}

.heading4 {
  font-size: 1.6vw;
  @media screen and (max-width: $screen-break) {
    font-size: 3.5vw;
  }
}

.heading5 {
  font-size: 1.4vw;
  @media screen and (max-width: $screen-break) {
    font-size: 3vw;
  }
}

.textXLarge {
  font-size: 3vw;
  @media screen and (max-width: $screen-break) {
    font-size: 4vw;
  }
}

.textLarge {
  font-size: 1.2vw;
  @media screen and (max-width: $screen-break) {
    font-size: 3vw;
  }
}

.textRegular {
  font-size: 1vw;
  @media screen and (max-width: $screen-break) {
    font-size: 2vw;
  }
}

.textSmall {
  font-size: .7vw;
  @media screen and (max-width: $screen-break) {
    font-size: 1.5vw;
  }
}

.textXSmall {
  font-size: .3vw;
  @media screen and (max-width: $screen-break) {
    font-size: .5vw;
  }
}

.textBold {
  font-weight: bold;
}

.textLighter {
  font-weight: lighter;
}

// ########## Margins
.vMargin1 {
  margin-top: .2vw !important;
  @media screen and (max-width: $screen-break) {
    margin-top: 1vw !important;
  }
}

.vMargin2 {
  margin-top: .5vw !important;
  @media screen and (max-width: $screen-break) {
    margin-top: 1.5vw !important;
  }
}

.vMargin3 {
  margin-top: 1vw !important;
  @media screen and (max-width: $screen-break) {
    margin-top: 4vw !important;
  }
}


.vMargin4 {
  margin-top: 1.5vw !important;
  @media screen and (max-width: $screen-break) {
    margin-top: 5vw !important;
  }
}

.vMargin5 {
  margin-top: 2vw !important;
  @media screen and (max-width: $screen-break) {
    margin-top: 6.5vw !important;
  }
}

.vMargin6 {
  margin-top: 3vw !important;
  @media screen and (max-width: $screen-break) {
    margin-top: 9vw !important;
  }
}

.vMargin7 {
  margin-top: 4vw !important;
  @media screen and (max-width: $screen-break) {
    margin-top: 12vw !important;
  }
}

.vMargin8 {
  margin-top: 5.5vw !important;
  @media screen and (max-width: $screen-break) {
    margin-top: 16vw !important;
  }
}

.vMargin9 {
  margin-top: 7vw !important;
  @media screen and (max-width: $screen-break) {
    margin-top: 22vw !important;
  }
}

.vMarginBottom1 {
  margin-bottom: .2vw !important;
  @media screen and (max-width: $screen-break) {
    margin-bottom: 1vw !important;
  }
}

.vMarginBottom2 {
  margin-bottom: .5vw !important;
  @media screen and (max-width: $screen-break) {
    margin-bottom: 1.5vw !important;
  }
}

.vMarginBottom3 {
  margin-bottom: 1vw !important;
  @media screen and (max-width: $screen-break) {
    margin-bottom: 4vw !important;
  }
}


.vMarginBottom4 {
  margin-bottom: 1.5vw !important;
  @media screen and (max-width: $screen-break) {
    margin-bottom: 5vw !important;
  }
}

.vMarginBottom5 {
  margin-bottom: 2vw !important;
  @media screen and (max-width: $screen-break) {
    margin-bottom: 6.5vw !important;
  }
}

.vMarginBottom6 {
  margin-bottom: 3vw !important;
  @media screen and (max-width: $screen-break) {
    margin-bottom: 9vw !important;
  }
}

.vMarginBottom7 {
  margin-bottom: 4vw !important;
  @media screen and (max-width: $screen-break) {
    margin-bottom: 12vw !important;
  }
}

.vMarginBottom8 {
  margin-bottom: 5.5vw !important;
  @media screen and (max-width: $screen-break) {
    margin-bottom: 16vw !important;
  }
}

.vMarginBottom9 {
  margin-bottom: 7vw !important;
  @media screen and (max-width: $screen-break) {
    margin-bottom: 22vw !important;
  }
}

.anchorLight {
  color: $colorTextLight;
  text-decoration: none;
}

.anchorLight:hover {
  color: $colorTextLight;
  text-decoration: underline;
}

.center {
  justify-content: center;
  align-content: center;
}
.centerFlex {
  @extend .center;
  display: flex;
}

.card-1 {
  box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);
  transition: all 0.3s cubic-bezier(.25,.8,.25,1);
}

.card-1:hover {
  box-shadow: 0 14px 28px rgba(0,0,0,0.25), 0 10px 10px rgba(0,0,0,0.22);
}

.card-2 {
  box-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23);
}

.card-3 {
  box-shadow: 0 10px 20px rgba(0,0,0,0.19), 0 6px 6px rgba(0,0,0,0.23);
}

.card-4 {
  box-shadow: 0 14px 28px rgba(0,0,0,0.25), 0 10px 10px rgba(0,0,0,0.22);
}

.card-5 {
  box-shadow: 0 19px 38px rgba(0,0,0,0.30), 0 15px 12px rgba(0,0,0,0.22);
}


.anchorLight {
  color: $colorTextLight;
  text-decoration: none;
}

.anchorLight:hover {
  color: $colorTextLight;
  text-decoration: underline;
}


