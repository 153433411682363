@import "../../layout/theme";
.container {
    text-align: center;
    max-width: 1000px;
    @media screen and (max-width: $screen-break) {
        max-width: 100%;
    }
}

.box {
    @extend .vMarginBottom7;
}